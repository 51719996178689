<template>
  <div class="box">
    <p class="title" v-html="'文章号：'+cId"></p>
    <img :src="payImg" alt="" />
    <p class="paynum">待支付金额：<span>10元</span></p>
  </div>
</template>

<script>
import xxpng from '../assets/payImg.jpg'
export default {
  // props: ["id"],
  data() {
    return {
      cId: '0000',
      payImg: xxpng
    }
  },
  created() {
    this.cId = this.$route.query.cId;
  }
}
</script>

<style>
.box{
  padding: 100px 50px;
  text-align: center;
}
.title{
  margin-bottom: 30px;
}
.paynum{
  margin-top: 30px;
}
.paynum span{
  color: #f80;
}
.box img{
  width: 300px;
  border-radius: 10px;
}
</style>
